/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

const Carousel_ELEMENT_SELECTOR = '';

const caruselselectors = {
	switcher: '.switcher__item',
	tabWrapper: '.js-tab-wrapper',
	tabs: '.js-tab',
	tabInner: '.js-tab__inner',
	// if draggeble in slider
	carouselholder: '.js-carousel-holder, .partners__content-item',
	slider: '.js-slider',
	slides: '.js-slide',
	trigger: '.trigger',
	controls: '.carousel__controls'
};

class Carousel extends GsapElement {
	shown: boolean = true;
	$tabs: JQuery;
	$tabsLink: JQuery;
	$tabWrapper: JQuery;
	tabs: CaruselTab[];
	controls: JQuery;
	activeTab: number;
	activeSlide: number;

	constructor(private $element: JQuery) {
		super('Carousel');
		if (!this.$element.length) return;

		this.tabs = [];

		this.controls = this.$element.find(caruselselectors.controls);

		let that = this;

		this.activeSlide = 0;
		this.activeTab = 0;

		this.$tabWrapper = this.$element.find(caruselselectors.tabWrapper);
		this.$tabs = this.$tabWrapper.find(caruselselectors.tabs);

		this.$tabs.each((index, element) => {
			this.tabs.push(new CaruselTab($(element)));
		});

		this.$tabsLink = this.$element.find(caruselselectors.switcher);

		this.$tabsLink.on('click', (e: any) => {
			this.setSlide(e);
		});

		this.tabs.forEach(p => {
			p.init();
		})

	}

	public init(): any {
		//this.tabsElements();
		super.onFlowInit();
		this.hideAll(this.$tabs);
	}

	public resize() {
		super.resize();
		this.tabs.forEach(p => {
			p.resize();
		})

		if (!this.$element.hasClass('partners')) {
			const height = $(this.$tabs[this.activeTab]).find('.js-carousel-holder').outerHeight(true);

			TweenMax.set(this.$tabWrapper, {height: height});
		}
	}


	public setSlide(e: any) {

		if (super.blockNextAnimation()) return

		let target = $(e.currentTarget);
		let id = target.index();

		if (this.activeTab === id) {
			return;
		}

		this.$tabsLink.removeClass('is-active');
		target.addClass('is-active');

		let $nextTab = $(this.$tabs[id]);
		let $prevTab = this.$tabs[this.activeTab];
		let $prevControls = $(`#${$($prevTab).attr('data-controls')}`);
		let $nextControls = $(`#${$nextTab.attr('data-controls')}`);
		this.activeTab = id;

		let nextTabHeight;
		(this.$element.hasClass('partners')) ? nextTabHeight = 'auto' : nextTabHeight = $nextTab.find(caruselselectors.carouselholder).outerHeight(true);

		let tlTab = new TimelineMax();

		this.flowContinue(
			tlTab
				.to($prevControls, 0.2, {autoAlpha: 0})
				.fromTo($prevTab, 0.3, {x: 0}, {x: -15, autoAlpha: 0, ease: Power3.easeInOut})
				.set(this.$tabWrapper, {height: nextTabHeight})
				.fromTo($nextTab, 0.3, {x: -15}, {
					x: 0, autoAlpha: 1, ease: Power3.easeInOut,
				})
				.to($nextControls, 0.1, {autoAlpha: 1})
		);
	}

	public hideAll(tabs: any) {
		tabs.each((i: any, tab: any) => {
			if (i != this.activeTab) {
				TweenMax.set(tab, {autoAlpha: 0});
			}
		});
	}


}


class CaruselTab extends GsapElement {

	$slider: JQuery;
	$slides: JQuery;
	itemsPerPageValues: number[];
	slidesLength: number;
	slideWidth: number;
	draggable: any;
	base: TimelineMax;
	inDragMode = false;
	tabWidth: number;
	hasDraggeble: boolean = false;
	$trigger: JQuery;
	$controls: JQuery;

	get itemsPerPage(): number {
		let x = 0;
		if (g.width < 600)
			return this.itemsPerPageValues[0];
		else if (g.width < 300) {
			return this.itemsPerPageValues[1];
		} else {
			return this.itemsPerPageValues[2];
		}
	}


	constructor(private $element: JQuery) {
		super('CaruselTab');
		if (this.$element.length && this.$element.find('.js-carousel-holder').length) {
			this.hasDraggeble = true;
			this.$slider = this.$element.find(caruselselectors.slider);
			this.$slides = this.$slider.find(caruselselectors.slides);
			this.$trigger = this.$element.find('.trigger');
			this.itemsPerPageValues = this.$element.data('slides-per-page');
			this.slidesLength = this.$slides.length;
			this.draggable = null;
			this.$controls = $(`#${$element.attr('data-controls')}`);
		}
	}

	public x = 1;

	public init(): any {
		this.initialized = true;
		if (this.hasDraggeble) {
			this.base = new TimelineMax({paused: true, ease: Power0.easeNone});
			this.createDraggable();
			this.resize();

			this.$controls.on('click', '.carousel__control--next', (e) => {
				let progress = this.base.progress()
				progress += (1 / this.$slides.length * 2);

				if (progress > 1)
					progress = 1;
				this.setPosition(progress);

			});

			this.$controls.on('click', '.carousel__control--prev', (e) => {
				let progress = this.base.progress()
				progress -= (1 / this.$slides.length * 2);

				if (progress < 0)
					progress = 0;
				this.setPosition(progress);
			});

		}
	}

	public createDraggable() {

		const html = document.querySelector('html');
		let offset =  -1 * Math.abs(window.pageYOffset);
		this.draggable = Draggable.create(this.$trigger, {
			type: 'x',
			trigger: this.$element.find($('.carousel__wrapper'))[0],
			throwProps: true,
			bounds: {left: 0, width: 100},

			onDragStart: () => {
				this.inDragMode = true;
				//TweenMax.set(html, {position: 'fixed', left: 0, right: 0, top: -offset});
			},
			onDrag: () => {
				this.updateAnimation();
			},
			onDragEnd: () => {
				//TweenMax.set(html, {position: 'static', left: 0, right: 0, top: -offset});

			},
			onThrowUpdate: () => {
				this.updateAnimation();
			},
		});
	}


	public resize() {

		if (!this.initialized)
			return false;
		if (this.hasDraggeble) {
			this.slideWidth = this.$element.width() / this.itemsPerPage;
			TweenMax.to(this.$slides, 0, {width: this.slideWidth});
			TweenMax.set(this.$slider, {width: this.slideWidth * this.$slides.length});

			this.tabWidth = this.$element.width();
			let min = this.tabWidth - this.slideWidth * this.$slides.length;
			this.draggable[0].applyBounds({left: 0, width: this.slideWidth * this.$slides.length - this.tabWidth});
			TweenMax.set(this.$trigger, {x: min * -1});

			this.base = new TimelineMax({paused: true, ease: Power0.easeNone});
			this.base.fromTo(this.$element.find('.carousel__wrapper'), 1, {x: 0, ease: Power0.easeNone}, {
				x: min,
				ease: Power0.easeNone
			});
		}
	}

	public updateAnimation = () => {
		let x = this.draggable[0].target._gsTransform.x;
		let percantage = 1 - (x / (this.slideWidth * this.$slides.length - this.tabWidth));

		if (percantage > 1) percantage = 1;
		if (percantage < 0) percantage = 0;
		this.base.progress(percantage);
	};


	public setPosition(progress: number) {

		let slideStep = (this.slideWidth * this.$slides.length - this.tabWidth) / this.$slides.length

		TweenMax.to(this.$trigger, .4, {x: (1 - progress) * (this.slideWidth * this.$slides.length - this.tabWidth)});
		TweenMax.to(this.base, .4, {progress: progress});
	}
}

