/// <reference path='../patterns/gsap-interface.ts' />

class CookieHTML {
    HTML: string;

    constructor(cookieText: string, agreeText: string, disagreeText: string) {
        this.HTML = `
            <div class="cookie-bar__wrapper">
                <div class="cookie-bar">
                    <div class="cookie-bar__container">
                        <div class="cookie-bar__text">${cookieText}</div>
                        <div class="cookie-bar__controls">
                            <!--<button class="disable">${disagreeText}</button>-->
                            <button class="enable btn btn--primary"><span>${agreeText}</span></button> 
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}

class Cookie extends GsapElement {
    initialized: boolean = false;
    shown: boolean = true;
    element: JQuery;
    cookieHTML: any;
    expire = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    button: JQuery;

    constructor(cookieText: string, agree: string, disagree: string) {
        super('Cookie');
        this.create(cookieText, agree, disagree);
        this.element = $('.cookie-bar');
        this.button = this.element.find('button');

        // bindings
        this.button.on('click', this.toggleCookie.bind(this));

        this.resize();
        this.init();

    }

    public init(): any {
        this.initialized = true;
    }

    public create(cookieText: string, agree: string, disagree: string) {
        this.cookieHTML = new CookieHTML(cookieText, agree, disagree);
        g.body.append(this.cookieHTML.HTML);
    }

    public show(timing: number): any {
        TweenLite.to(this.element, 0.3 * timing, {y: '0%', autoAlpha: 1, ease: Power3.easeIn});
        this.shown = true;
    }

    public hide (timing: number): any {
        TweenLite.to(this.element, 0.3 * timing, {y: '100%', autoAlpha: 0, ease: Power3.easeOut});
        this.shown = false;
    }

    public enable() {
        document.cookie = Global.cookie.name + '=true; expires= ' + this.expire;
    }

    public disable() {
        document.cookie = Global.cookie.name + '=false; expires= ' + this.expire;
    }

    public toggleCookie = () => {
        if (this.button.hasClass('disable')) {
            this.disable();
        } else {
            this.enable();
        }

        if (!this.shown) {
            this.show(1);
        } else {
            this.hide(1);
        }
    };

    public resize() {

        if (!this.initialized) return false;

        if (this.shown) {
            this.show(0);
        } else {
            this.hide(0);
        }
    }
}