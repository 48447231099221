/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

const Intro_ELEMENT_SELECTOR = '.intro';

class Intro extends GsapElement {
    shown: boolean = true;
    animated: boolean;
    $slider: JQuery;
    tl: TimelineMax;
    tlIntro: TimelineMax;
    switcher: TimelineMax;
    $headings: JQuery;
    $headingsLabel: JQuery;
    $headingsWrapper: JQuery;
    $line: JQuery;
    $lineWrapper: JQuery;
    $paginator: JQuery;
    $counter: JQuery;
    $images: JQuery;
    currentSlide: number;
    prevSlide: number;
    nextSlide: number;
    expectSlides: number;
    slideDurr: number;
    lineWrapperWidth: number;
    selectors = {
        headings: '.intro__carousel-heading',
        images: '.intro__carousel-image',
        headingsWrapper: '.intro__carousel-left',
        headingsLabel: '.intro__carousel-label',
        lineWrapper: '.intro__carousel-line',
        line: '.intro__carousel-line span',
        paginator: '.intro__carousel-paginator',
        counter: '.intro__carousel-number--count',
        scrollTrigger: '.'
    };

    constructor(private $element: JQuery) {
        super('Intro');

        if (!this.$element.length) return;
        this.vars();

        this.expectSlides = 1;
        this.currentSlide = 0;
        if (this.currentSlide <= 0) {
            this.currentSlide += this.expectSlides;
        }
        this.nextSlide = this.clamp(this.currentSlide + 1, 0, this.$images.length);

        this.slideDurr = 0.4;
        this.prevSlide = this.clamp(this.currentSlide - 1, 0, this.$images.length);
    }


    public vars() {
        this.animated = true;
        this.tl = new TimelineMax();
        this.tlIntro = new TimelineMax({repeat: -1});
        this.switcher = new TimelineMax();
        this.$headings = $(this.selectors.headings);
        this.$headingsWrapper = $(this.selectors.headingsWrapper);
        this.$headingsLabel = this.$headings.find(this.selectors.headingsLabel);
        this.$line = $(this.selectors.line);
        this.$lineWrapper = $(this.selectors.lineWrapper);
        this.$paginator = $(this.selectors.paginator);
        this.$counter = $(this.selectors.counter);
        this.$images = $(this.selectors.images);
        this.expectSlides = 1;
        this.currentSlide = 0;
        this.lineWrapperWidth = this.$lineWrapper.width();
    }

    public init(): any {


        this.getHeight();

        TweenMax.set(this.$line, {opacity: 1});
        this.tlIntro
            .set(this.$line, {transformStyle: "preserve-3d"})
            .to(this.$line, 3, {xPercent: 100, ease: Linear.ease})
            .fromTo(this.$line, 0.3, {opacity: 1}, {
                opacity: 0, ease: Linear.ease, onComplete: () => {
                    this.animate(this.$images);
                }
            });

        this.scroll();
    }

    public scroll() {
        document.addEventListener('scroll', (e) => {

            if (this.viewport('.intro')) {

                if (this.animated) {
                    return;
                }
                this.tlIntro.repeat(-1).repeatDelay(0).play();
                this.animated = true;
            } else {

                this.tlIntro.repeat(0).paused(true);
                this.animated = false;
            }
        });
    }

    public viewport(element: any) {
        let elementTop = $(element).offset().top;
        let elementBottom = elementTop + $(element).outerHeight();
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    public clamp(val: number, min: number, max: number) {
        return val > max ? max : val < min ? min : val;
    }

    public getHeight() {
        let heightArr: any = [];
        this.$headingsLabel.each((i, heading) => {
            return heightArr.push($(heading).outerHeight(true));
        });
        TweenMax.set(this.$headingsWrapper, {height: Math.max(...heightArr)});
        return TweenMax.to(this.$paginator, 0.3, {autoAlpha: 1, opacity: 1, y: 0, ease: Power3.easeInOut});
    }

    /**
     * Animate function
     * @param {JQuery} arr
     */
    public animate(arr: JQuery) {
        this.slideDurr = 0.5;
        if (this.currentSlide < 0 || this.currentSlide >= arr.length) {
            this.currentSlide = 0;
        }
        if (this.nextSlide < 0 || this.nextSlide > arr.length) {
            this.nextSlide = this.currentSlide + 1;
        }
        if (this.prevSlide >= arr.length) {
            this.prevSlide = 0
        }
        this.tl
            .to(this.$counter, this.slideDurr / 2, {y: 5, autoAlpha: 0, ease: Power3.easeInOut})
            .set(this.$counter, {text: `O${this.currentSlide + 1}`})
            .to(this.$counter, this.slideDurr / 2, {y: 0, autoAlpha: 1, ease: Power3.easeInOut})

            .addLabel('start', `-=${this.slideDurr} + ${this.slideDurr * 2}`)
            .fromTo(this.$images[this.prevSlide], this.slideDurr, {autoAlpha: 1}, {
                autoAlpha: 0,
                ease: Power3.easeInOut
            }, 'start * 1.2')
            .fromTo(this.$headings[this.prevSlide], this.slideDurr, {autoAlpha: 1}, {
                autoAlpha: 0,
                ease: Power3.easeInOut
            }, `-=${this.slideDurr}`)
            .addLabel('fadeIn', '+=0')
            .fromTo(this.$images[this.currentSlide], this.slideDurr, {autoAlpha: 0}, {
                autoAlpha: 1,
                ease: Power3.easeInOut
            }, 'fadeIn')
            .fromTo(this.$headings[this.currentSlide], this.slideDurr, {autoAlpha: 0}, {
                autoAlpha: 1, ease: Power3.easeInOut, onComplete: () => {
                    this.currentSlide++;
                    this.prevSlide = this.currentSlide - 1;
                    this.nextSlide = this.currentSlide + 1;
                }
            }, 'fadeIn');
    }

    public resize() {
        this.getHeight();
    }

}