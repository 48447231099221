
enum AppStateActions {
	InitState,
	MenuChange,
}


class AppState {
	menuOpen: boolean = false;
	payload: any;
	type: AppStateActions

	constructor() {
		this.type = AppStateActions.InitState;
	}

	cloneState(object: AppState): AppState {
		this.menuOpen = object.menuOpen;
		this.type = object.type;
		return this;
	}

	setMenuOpen(open: boolean): AppState {
		this.menuOpen = open;
		return this;
	}

	setPayload(payload: any): AppState {
		this.payload = payload;
		return this;
	}

	setType(type: AppStateActions): AppState {
		this.type = type;
		return this;
	}

}



