class GAObject {
	event: string = "UserInteraction"
	eventCategory: string // ContactForm nebo InterierControl nebo FlatTable
	eventAction: string
	eventLabel: string = null // pokud neni potreba
	eventValue: string = null // pokud neni potreba
	constructor() {

	}
	set(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: string = null): GAObject {
		this.eventCategory = eventCategory;
		this.eventAction = eventAction;
		this.eventLabel = eventLabel;
		this.eventValue = eventValue;
	
		return this;
	}
}