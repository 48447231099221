/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

const PictureControl_OBJECTFIT_SUPPORT = (('objectFit' in document.documentElement.style === true));

class PictureControl implements Igsap {
    initialized: boolean = false;
    shown: boolean = false;
    name: string = 'PictureControl';
    img: HTMLPictureElement;

    constructor(private element: HTMLElement, id: number) {
        this.img = element.querySelectorAll('img')[0] as HTMLPictureElement;
        this.name += id;
        if (!PictureControl_OBJECTFIT_SUPPORT) {
            let src = this.img.getAttribute("src") === null ? this.img.getAttribute("data-src") : this.img.getAttribute("src");
            this.img.style.display = 'none';

            this.element.style.backgroundImage = `url("${src}");`;

            // setting background-size from object-fit
            // typeof getComputedStyle !== 'undefined' ? this.element.style.backgroundSize = this.img.currentStyle['object-fit'] : this.element.style.backgroundSize = window.getComputedStyle(this.img, null).getPropertyValue('object-fit');

        }

        this.resize();
    }

    public init(): any {
        this.initialized = true;
    }

    public resize() {
        if (!this.initialized) return false;
    }
}