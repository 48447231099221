/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

const Tabs_ELEMENT_SELECTOR = '';

class Tabs extends GsapElement {


    constructor(private $element: JQuery) {
        super('Tabs');
        if (!this.$element.length) return;
        this.vars();
    }

    public vars() {

    }

    public init(): any {

    }

    public resize() {

    }

}