
/// <reference path="../definition/jquery.d.ts" />r

function logFunction (sender: Igsap, message: string){
    if(g.log)
        console.log(sender.name + " " + message)

}




