class Contact extends GsapElement {
    tl: TimelineMax;
    switchWrapper: JQuery;
    contactSwitchTrigger: JQuery;
    map: JQuery;
    visual: JQuery;
    animating: boolean;
    is = {
        switched: false,

    };
    selectors = {
        switchWrapper: '.contact__map-wrapper',
        switchTrigger: '.contact__switch-trigger',
        map: '.contact__map',
        visual: '.contact__visual'
    };

    classes = {
        visual: 'contact__visual',
        is: {
            backside: 'is-backside'
        }
    };

    constructor(private $element: JQuery) {
        super('Contact');
        if (!this.$element.length) return;
        this.vars();
    }

    public vars() {
        this.tl = new TimelineMax();
        this.switchWrapper = this.$element.find(this.selectors.switchWrapper);
        this.contactSwitchTrigger = this.switchWrapper.find(this.selectors.switchTrigger);
        this.map = this.$element.find(this.selectors.map);
        this.visual = this.$element.find(this.selectors.visual);
        this.animating = false;
    }

    public init(): any {
        this.bindEvents();
    }

    public bindEvents() {

        this.contactSwitchTrigger.on('click', (e) => {
        /*     if(g.width <= 500) {
                return;
            } */
            if ($(e.currentTarget).hasClass(this.classes.visual) && !this.animating) {
                this.animating = true;
                this.switch();
            } else {
                this.animating = true;
                this.switch(false);
            }


        });
    }

    public switch(dir: boolean = true) {

        if (dir) {
            this.tl.clear();
            this.tl
                .to(this.visual, 0.4, {
                    zIndex: 2,
                    x: 0, ease: Power3.easeInOut, onComplete: () => {
                        this.switchWrapper.addClass(this.classes.is.backside);
                    }
                })
                .to(this.map, 0.4, {
                    zIndex: 1, x: 0, ease: Power3.easeInOut, onComplete: () => {
                        this.animating = false;
                    }
                }, 0);
        } else {
            this.tl.clear();
            this.tl
                .to(this.map, 0.4, {
                    zIndex: 2,
                    x: 15, ease: Power3.easeInOut, onComplete: () => {
                        this.switchWrapper.removeClass(this.classes.is.backside);
                    }
                })
                .to(this.visual, 0.4, {
                    zIndex: 1, x: -15, ease: Power3.easeInOut, onComplete: () => {
                        this.animating = false;
                    }
                }, 0);
        }



    }

    public resize() {

    }
}
