/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

class VimeoContainer extends GsapElement {
    shown: boolean = true;
    $close: JQuery;
    $video: JQuery;
    $videoContainer: JQuery;
    $cover: JQuery;
    $trigger: JQuery;
    $triggerLabel: JQuery;
    $info: JQuery;
    tl: TimelineMax;
    player: any;
    options: any;
    modal: tingle;
    playPromise: any;
    is = {
        playing: false,
        dirty: false
    };
    selectors = {
        video: '.carousel__item-video',
        videoContainer: '.popupVideoContainer',
        trigger: '.trailer__cta',
        label: '.trailer__cta-label',
        cover: '.carousel__item-image picture',
        info: '.trailer__info',
        close: '.close'
    };

    constructor(private $element: JQuery) {
        super('VimeoContainer');
        if (!this.$element.length) return;
        this._vars();
    }

    private _vars() {
        this.tl = new TimelineMax();
        this.$video = this.$element.find(this.selectors.video);
        this.$videoContainer = this.$element.find(this.selectors.videoContainer);
        this.$trigger = this.$element.find(this.selectors.trigger);
        this.$triggerLabel = this.$trigger.find(this.selectors.label);
        this.$info = this.$element.find(this.selectors.info);
        this.$cover = this.$element.find(this.selectors.cover);
        this.$close = this.$element.find(this.selectors.close);

        this.options = {
            id: 187309365,
            width: $(document).innerWidth(),
            loop: true
        };
    }

    public init(): any {
    }


    public play() {

        this.$element.on('click', (e) => {

            if (this.is.playing) {
                return false;
            }
            this.is.playing = true;
            let item = $(e.currentTarget);
            let id = item.data('video-id');

            this.modal.open().setContent('<div class="popupVideoContainer"></div>');
            this.$videoContainer = $('.popupVideoContainer');

            let options = {
                id: id,
                width: this.$videoContainer.innerWidth(),
                loop: true
            };

            this.player = new Vimeo.Player(this.$videoContainer, options);

            this.player.loadVideo(options.id).then(() => {
                this.playPromise = this.player.play();
            });
        });

    }
}
