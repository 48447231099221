
class Loader {
    public container: Element;
    public drop: Element;
    public drop2: Element;
    public outline: Element;
    public tl: any;

    constructor() {
        this.container = document.getElementById('loading');
        this.drop = document.getElementById('drop');
        this.drop2 = document.getElementById('drop2');
        this.outline = document.getElementById('outline');
    }

    startLoader() {

        this.tl = new TimelineMax({
            repeat: -1,
            paused: false,
            repeatDelay: 0,
            immediateRender: false
        });

        TweenMax.set(this.container, {display: 'block'});
        TweenMax.from(this.container, 0.3, {autoAlpha: 0});

        TweenMax.set(this.drop, {
            transformOrigin: '50% 50%'
        });

        this.tl.timeScale(3);

        this.tl
            .to(this.drop, 4, {
                attr: {
                    cx: 250,
                    rx: '+=10',
                    ry: '+=10'
                },
                ease: Back.easeInOut.config(3, null)
            })
            .to(this.drop2, 4, {
                attr: {
                    cx: 250
                },
                ease: Power1.easeInOut
            }, '-=4')
            .to(this.drop, 4, {
                attr: {
                    cx: 125,
                    rx: '-=10',
                    ry: '-=10'
                },
                ease: Back.easeInOut.config(3, null)
            })
            .to(this.drop2, 4, {
                attr: {
                    cx: 125,
                    rx: '-=10',
                    ry: '-=10'
                },
                ease: Power1.easeInOut
            }, '-=4')
    }

    endLoader() {
        TweenMax.set([this.container, this.drop, this.drop2], {clearProps: 'all'});
        TweenMax.set([this.drop, this.drop2], {attr: { rx: 20, ry: 20, cx: 125 }});

        this.tl.kill();
    }
}