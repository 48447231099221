
/// <reference path="./definition/greensock.d.ts" />
/// <reference path="./definition/jquery.d.ts" />
/// <reference path="./definition/nette.ajax.d.ts" />
/// <reference path="./definition/jquery.d.ts" />
/// <reference path='./definition/gui.d.ts' />
/// <reference path="./global/library.ts" />
/// <reference path="./global/app-state.ts" />
/// <reference path='./global/ga-event.ts' />
/// <reference path="elements.ts" />

declare var Global: any;
declare var dataLayer: any;
declare var Vimeo: any;
declare var ScrollMagic: any;
declare var Headroom: any;
declare var google: any;



class globalVariables {
  public html: JQuery;
  public body: JQuery;
  public nav: JQuery;
  public GoogleMap: any;

  public header: JQuery;

  public height: number;
  public width: number;
  public realHeight: number;

  public gsap: gsapObjects;
  public loader: Loader;

  public controller: any;
  public scene: any = [];

  public hover = false;

  public debug = false;
  public log = false;

  public mobile650 = 650;
  public footer : Footer;

  public gui: dat.GUI; // dat.

  private idIterator = 0;

  public isGlobal = false;
  public cookieBar: Cookie;

  private _appState: Subject<AppState>;
  //subject getter for subscribing
  get appState(): Subject<AppState> {
    return this._appState
  }
  /// Get new instance cloned from aktual state
  get newState(): AppState {
    return new AppState().cloneState(this._appState.getValue());
  }
  
  getNewState(type: AppStateActions): AppState {
    return new AppState().cloneState(this._appState.getValue()).setType(type);
  }

  constructor() {
    this._appState = new Subject<AppState>(new AppState());
    if (typeof Global != "undefined")
      this.isGlobal = true;
    if (this.debug)
      this.gui = new dat.GUI();
  }

  /// Update All controlers of data.gui
  public updateDatGui() {
    if (this.debug && this.gui) {
      for (var i in this.gui.__controllers) {
        this.gui.__controllers[i].updateDisplay();
      }
    }
  }
  /// properties to global data-gui
  public addTracking(obj: any, properties: string[]) {
    if (this.debug && this.gui) {
      for (let i = 0; i < properties.length; i++) {
        this.gui.add(obj, properties[i]);
      }
    }
  }

  // UNIQ id counter
  public getUniqId() {
    return this.idIterator++;
  }

  public pushGaEvent(gaObject: GAObject) {
      if (typeof dataLayer !== 'undefined') {
        if(this.debug && gaObject.eventValue != null){
          console.warn(`GAObject - property value "${gaObject.eventValue}" will be not possible to see in Google Analytics`)
        }
          dataLayer.push(gaObject);;
      }
  }

  public getUrlParams(parameter: string): any {
      let result = null;
      let tmp = [];

      window.location.search.substr(1).split("&")
          .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameter) result = decodeURIComponent(tmp[1]);
          });

      return result;
  }
}

var g: globalVariables = new globalVariables();
