/// <reference path="global.ts" />

class Triggers {

	constructor() {
		this.init();
	}

	public init() {
		this.initSliders();
		this.initSimpleSubscribers();
		this.initClickTriggers();
		this.initGAEvents();

		//g.body.on('click', '[data-contact-popup-close]', function () {
		//	let newState = g.newState.setContactPopupOpen(false);
		//	newState.setType(AppStateActions.ContactPopupChange);
		//	g.appState.next(newState);
		//})

	}

	private initClickTriggers() {
		//g.body.on('click', '[data-contact-popup-close]', function () {
		//	let newState = g.newState.setContactPopupOpen(false);
		//	newState.setType(AppStateActions.ContactPopupChange);
		//	g.appState.next(newState);
		//})
	}

	private initSliders() {
		//$('.hp-header--bg__slider').slick({
		//	slidesToshow: 1,
		//	slidesToScroll: 1,
		//	arrows: true,
		//	autoplaySpeed: 3000,
		//	fade: true,
		//	prevArrow: $('.hp-header .slick-arrows__prev'),
		//	nextArrow: $('.hp-header .slick-arrows__next'),
		//});

	}
	private initSimpleSubscribers() {
		//// Simple Subscribers - subscribe specific state a change something 
		// EXAMPLE
		////Set Selectors
		//let items = $("selector")
		//if (items.length != 0) {
		//	g.appState.subscribe(p => {
		//		//Cahnge SpecificType
		//		if (p.type == AppStateActions.SpecificType || p.type == AppStateActions.InitState) {
		//			//Do your job
		//			change-something
		//		}
		//	})
		//}
	}

	public initGAEvents() {
		//$('.elector').on('mouseenter click tap', function () {
		//	g.pushGaEvent(new GAObject().set('category', 'action', 'label', 'useless value'));
		//});

		//g.appState.subscribe(p => {
		//	if (p.type == AppStateActions.SpecificState) {
		//		g.pushGaEvent(new GAObject().set('category', 'action', 'label', 'useless value'));
		//	}
		//});
	}

}


