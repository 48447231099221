class Trailer extends GsapElement {
    shown: boolean = true;
    $video: JQuery;
    $cover: JQuery;
    $trigger: JQuery;
    $triggerLabel: JQuery;
    $info: JQuery;
    $modal: JQuery;
    tl: TimelineMax;
    player: any;
    options: any;
    is = {
        playing: false
    };
    selectors = {
        video: '.trailer__video',
        trigger: '.trailer__cta',
        label: '.trailer__cta-label',
        cover: '.trailer__cover',
        info: '.trailer__info',
        modal: '.trailer--video'
    };

    constructor(private $element: JQuery) {
        super('Trailer');
        if (!this.$element.length) return;
        this._vars();
    }

    private _vars() {
        this.tl = new TimelineMax();
        this.$video = this.$element.find(this.selectors.video);
        this.$trigger = this.$element.find(this.selectors.trigger);
        this.$triggerLabel = this.$trigger.find(this.selectors.label);
        this.$info = this.$element.find(this.selectors.info);
        this.$cover = this.$element.find(this.selectors.cover);
        this.$modal = $('.trailer--video');
        this.options = {
            id: 300757981,
            width: $(document).innerWidth(),
            loop: true
        };
    }

    public init(): any {

        this.$trigger.on('click', () => {
            let openModal = new TimelineMax();
                openModal
                    .to(this.$modal, 0.2, {autoAlpha: 1})
        });

        this.$modal.on("click", () => {
            const target = event.target;
            if ($(target).hasClass('trailer--video')) {
                this.hide();
            }
        });

        $('.trailer--video .modal-video-close-btn').on('click', () => {
            this.hide();
        })

        $(document).keyup((event: KeyboardEvent) => {
            if (event.key === "Escape" || (event.which === 27)) {
                this.hide();
            }
        });

    }

    public hide() {
        let closeModal = new TimelineMax();
            closeModal
                .to(this.$modal, 0.2, {autoAlpha: 0})
    }

    public resize() {

    }
}
