/// <reference path='../patterns/gsap-element.ts' />
/// <reference path='../patterns/subject.ts' />


class RootSlider implements Igsap {
	initialized: boolean
	shown: boolean;
	name: string = 'RootSlider';


	// options
	numbers = true; //nubmercontrol
	arrows = true;
	//
	slider: any;
	slides: NodeListOf<Element>;
	proxy: any;

	arrowleft: any;
	arrowright: any;


	slideWidth = 300;
	rotationX = 15;

	base: TimelineMax;
	animation: TimelineMax;
	movetimeline: TimelineMax;

	slideStep: number;

	wrapWidth: number;

	slidesToMinus = -3;

	actualSlide: Subject<number>;
	draggable: any;
	inDragMode = false;

	isOdd: boolean = false;;
	offset: number = 0; // if oddd


	gui: any;
	guiX: number = 0;
	guiprogress: number = 0;
	guiSlide: number = 0;

	debug = true;

	numbercontrol: SliderNumberControl;

	constructor(protected sliderwrapper: HTMLElement, id: number) {
		let that = this;
		this.name += id;
		this.slider = this.sliderwrapper.querySelector(".slider");
		this.slides = this.sliderwrapper.querySelectorAll(".slider > div");
		this.proxy = document.createElement("span");

		this.slideStep = 1 / this.slides.length;
		this.wrapWidth = this.slideWidth * this.slides.length;

		this.actualSlide = new Subject<number>(1);

		sliderwrapper.querySelector('.slide-arrow-next').addEventListener('click', this.increment.bind(this));
		sliderwrapper.querySelector('.slide-arrow-prev').addEventListener('click', this.decrement.bind(this));

		this.isOdd = this.slides.length % 2 == 1;
		if (this.isOdd) this.offset = this.slideWidth / 2;


		this.actualSlide.subscribe(p => {
			if (!that.animation)
				return;

			this.updateGUI();
		});
	}

	public init(): any {
		this.initialized = true;
		let that = this;
		this.base = new TimelineMax({ paused: true, ease: Power0.easeIn });
		this.movetimeline = new TimelineMax();
		for (var i = 0; i < this.slides.length; i++) {
			that.initSlide(that.slides[i], i);
		}

		this.animation = new TimelineMax({ repeat: -1, paused: true, ease: Power0.easeIn })
		this.animation.add(that.base.tweenFromTo(1, 2));
		//this.animation.progress(1 + (1 / (parseInt((this.slides.length / 2) + "") + 1)));

		let initX = ((that.slides.length / 2) - 1) * that.slideWidth - this.offset

		TweenMax.to(this.proxy, 0, { x: initX });

		this.draggable = new Draggable(this.proxy, { // proxy fake
			// allowContextMenu: true,  
			trigger: that.slider,
			throwProps: true,
			onDragStart: function () {
				that.inDragMode = true;
			},
			onDrag: function () { // TODO:// ehy it needs this.x
				//console.log("onDrag: " + this.x / that.wrapWidth);
				that.updateAnimation();
			},
			onThrowUpdate: function () {
				//console.log("onThrowUpdate: " + this.x / that.wrapWidth);
				that.updateAnimation();
			},
			onThrowComplete: function () {
				that.calculateAktualSlide();
				that.inDragMode = false;
			},
			onDragEnd: function () {

			},
			snap: {
				x: that.snapX.bind(that)
			}

		});


		// Add tracking
		if (this.debug) {
			g.addTracking(this, ['guiX', 'guiprogress', 'guiSlide', 'slideStep', 'slideWidth']);
		}

		this.updateAnimation();
		this.calculateAktualSlide()

		// Added feature
		if (this.numbers)
			this.numbercontrol = new SliderNumberControl(this.sliderwrapper.querySelector("ul"), this);
	}

	public resize() {
		if (!this.initialized) return false;
	}

	public updateAnimation = () => {
		//console.log(Math.abs((-1 *(this.draggable.x / this.wrapWidth))%1));
		this.animation.progress(this.proxy._gsTransform.x / this.wrapWidth);

		this.updateGUI();
	}

	public calculateAktualSlide() {
		let that = this;
		let first = (.5 - (that.isOdd ? that.slideStep * .5 : 0));
		let ss = ((1 - that.animation.progress()) + first);

		if (ss > 1)
			ss -= 1;

		let actualSlide = Math.round(ss / that.slideStep);
		that.actualSlide.next(actualSlide);

		this.updateGUI();
	}

	// TRiggered movement
	public increment() {
		this.setSlide(this.actualSlide.getValue() + 1)
	}

	public decrement() {
		this.setSlide(this.actualSlide.getValue() - 1)
	}

	public setSlide(newSlide: number) {
		let that = this;
		let aktual = this.actualSlide.getValue();
		let change = aktual - newSlide;
		//find closest path
		if (change > this.slides.length / 2) {
			change -= this.slides.length
		}
		else if (change < this.slides.length / -2) {
			change += this.slides.length
		}
		//move
		if (!this.inDragMode) {
			this.movetimeline
				.to(this.animation, .4, {
					progress: this.animation.progress() + (change * this.slideStep)
				})
				.to(this.proxy, .4, {
					x: this.proxy._gsTransform.x + (change * this.slideWidth),
					onComplete: function () {
						that.calculateAktualSlide();
						that.draggable.update();
					}
				}, "-=.5");
		}
		this.updateGUI();
	}

	public initSlide = (item: any, index: number) => {
		let that = this;
		var tl = new TimelineMax({ repeat: 1, ease: Power0.easeIn });

		tl.fromTo(item, 1,
			{ x: that.slideWidth * (that.slides.length / -2) + this.offset, scale: .8, ease: Power0.easeIn },
			{ x: that.slideWidth * (that.slides.length / 2) + this.offset, ease: Power0.easeIn },
			0)
			.to(item, that.slideStep, { scale: 1, repeat: 1, yoyo: true, ease: Power0.easeIn },
				(.5 - (that.isOdd ? this.slideStep * 1.5 : this.slideStep)));

		that.base.add(tl, -that.slideStep * index);
	}

	private snapX(x: number) {
		return Math.round(x / this.slideWidth) * this.slideWidth;
	}

	public updateGUI = () => {
		if (g.debug && this.debug && g.gui) {
			this.guiX = this.draggable.x;
			this.guiprogress = this.animation.progress();
			this.guiSlide = this.actualSlide.getValue();
			g.updateDatGui();
		}
	}

}



class SliderNumberControl {

	items: SliderNumberItem[] = [];

	constructor(private element: HTMLElement, private parent: RootSlider) {
		let that = this;
		this.parent.actualSlide.subscribe(p => {
			this.items.forEach(item => {
				item.setUpState(p);
			});
		});

		this.reInit()
	}

	public reInit() {
		let that = this;
		for (let index = 1; index < this.parent.slides.length + 1; index++) {
			this.element.insertAdjacentHTML('beforeend', `<li data-id="${index}"><span>${index}</span></li>`);
		}

		this.items = [];
		let elements = this.element.querySelectorAll("li");
		let i = 1;
		Array.prototype.forEach.call(elements, function (el: HTMLElement) {
			that.items.push(new SliderNumberItem(el, i))
			el.addEventListener('click', function () {
				that.parent.setSlide(parseInt($(this).attr("data-id")));
			});
			i++;
		});


	}
}

class SliderNumberItem {

	private shown: boolean;


	constructor(private element: HTMLElement, private id: number) {

	}

	public setUpState(activeId: number) {
		// CHange state if it needed
		let value = (activeId == this.id);
		if (value != this.shown) {
			value ? this.show() : this.hide();
			this.shown = value;
		}
	}

	public show() {
		TweenMax.to(this.element, .2, { scale: 1 });
	}

	public hide() {
		TweenMax.to(this.element, .2, { scale: .7 });
	}

}