/// <reference path="triggers.ts" />

$(function () {
    //$.nette.init();
    //g.controller = new ScrollMagic.Controller();

    g.html = $("html");
    g.body = $("body");
    g.nav = $("nav");

    g.height = $(window).innerHeight();
    g.realHeight = window.innerHeight;
    g.width = $(window).innerWidth();

    g.gsap = new gsapObjects();
    g.loader = new Loader();

    resize();

    let tempHeight = 0;
    let tempWidth = 0;

    var doit: any;
    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(resize, 100);
    };

    function resize() {
        // Get dimensions
        g.height = $(window).innerHeight();
        // Real height on safari iPhone - height is vh unit - innerHeight is 100% of window
        g.realHeight = window.innerHeight;
        g.width = g.html.outerWidth();

        // This skip event resize on iOS devices - if header was only scaled-out
        // if you need - you can delete this condition
        if (tempWidth == g.width && tempHeight == g.height) {
            return false;
        }

        tempWidth = g.width
        tempHeight = g.height

        // Detect hover events
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || g.width < 600)) {
            g.html.addClass("hover");
            g.hover = true;
        } else {
            g.html.removeClass("hover");
            g.hover = false;
        }

        // Destroy colection od scenes
        // TODO: implement ScrollMagic screene in resize function
        /*if (g.controller != null) {
            for (let scene of g.scene) {
                scene.destroy(true);
            }
            g.scene = [];
        }
        g.controller = new ScrollMagic.Controller();*/

        //Elements resize
        g.gsap.resize();
    }


    g.body.on('click', '[data-scroll]', function (e) {
        // maybe hamburger menu close
        //e.preventDefault();
        var $this = $(this);
        var target = $($this.attr('data-scroll'));
        var element: any = $($this.attr('data-element'));
        var attrDelay = $this.attr('data-delay');
        var attrDuration = $this.attr('data-duration');
        var attrOffset = $($this).attr('data-offset');
        var attrPos = $($this).attr('data-center');

        var delay = 0;
        var duration = .6;
        var offset = 0;
        var pos = 0;// maybe nav.height()


        // if delay and duration ware set up
        if (typeof element !== typeof undefined) {
            element = window;
        }
        if (typeof attrDelay !== typeof undefined) {
            delay = parseInt(attrDelay);
        }
        if (typeof attrDuration !== typeof undefined) {
            duration = parseInt(attrDuration);
        }
        if (typeof attrOffset !== typeof undefined) {
            offset = parseInt(attrOffset);
        }
        if (typeof attrPos !== typeof undefined) {
            pos = parseInt(attrPos);
        }

        if (g.debug) {
            if (!target.length) {
                console.error('Target element not found');
            }
        }
		if (parseInt(attrPos) != 50 || parseInt(attrPos) != 100) {
			pos = -1 * parseInt(attrPos);
		}
        if (parseInt(attrPos) === 50) {
            pos = -1 * ((g.height - $(target).height()) / 2);
        }
        if (parseInt(attrPos) === 100) {
            pos = $(target).height();
        }

        if (offset === 0) {
            offset = pos;
        }

        var targetWithOffset = target.offset().top + offset;

        if($this.data('mobile-nav') && g.mobile650) {
            let headerSymlink = g.gsap.elements.Header0;
            if (headerSymlink.is.mobileOpen) {
                headerSymlink.showMobileMenu(false);
                headerSymlink.tl.reverse()
            }
        }

        TweenMax.to(element, duration, {
            delay: attrDelay,
            scrollTo: {y: targetWithOffset, autoKill: false},
            ease: Power3.easeInOut
        });
    });

    // -----------------------------
    // ------ debug on arrows  -----
    // -----------------------------
    //g.body.keydown(function (e) {
    //    switch (e.which) {
    //        case 37: // left
    //            break;
    //        case 38: // up
    //            break;
    //        case 39: // right
    //            break;
    //        case 40: // down
    //            break;
    //        default: return;
    //    }
    //});

    $(window).load(function () {
        //resize po nacteni obrazku
        window.setTimeout(function () {
            g.gsap.afterLoadInitialization();
            resize();
        }, 0);
    });

    /*$.nette.ext('form', {
        before: function () {
           g.loader.startLoader();
        },
        success: function () {
            //Maybe this should be moved to "complete option", but do it carefully
            g.gsap.afterLoadInitialization();
        },
        complete: function () {
            g.loader.endLoader();
        }
    });*/
});

