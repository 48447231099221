/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../patterns/gsap-interface.ts' />

class Header extends GsapElement {
    shown: boolean = true;
    $burger: JQuery;
    $login: JQuery;
    $menu: JQuery;
    $menuItem: JQuery;
    $pageNavigation: JQuery;
    $pageNavItems: JQuery;
    $pageNavLabel: JQuery;
    headerHeight: number;
    tl: TimelineLite;
    tlMobileMenu: TimelineLite;
    tlMenuList: TimelineLite;
    initScenes: any; // Array<ScrollMagic.Scene> ?? TODO zeptat se na pole scrollmagic scen
    scene: any;
    headroom: any;
    top: JQuery;
    mid: JQuery;
    bot: JQuery;
    selectors = {
        burger: '.hamburger',
        login: '#login-cta',
        top: '.hamburger__top',
        mid: '.hamburger__mid',
        bot: '.hamburger__bot',
        menu: {
            root: '.header__menu',
            item: '.header__menu-item'
        },
        navigation: {
            root: '.page__nav',
            item: '.page__nav-item',
            label: '.page__nav-label'
        }
    };
    is = {
        animating: false,
        inited: false,
        mobileOpen: false,
        mobileMenuAnimating: false
    };

    mobileMenuAnimDurr = 0.3;

    constructor(private $element: JQuery) {
        super('Header');
        let _that = this;
        if (!this.$element.length) return;
        this._vars();
        this.bindEvents();

        if(g.mobile650) {
            this.headerHeight  = 25;
        }

        this.headroom = new Headroom(this.$element[0], {
            "offset": this.headerHeight,
            "classes": {
                "initial": "header--fixed",
                "pinned": "slideDown",
                "unpinned": "slideUp",
                "top": "top",
                "notTop": "not-top",
            }
        });
        this.headroom.init();
    }

    private _vars() {
        this.$burger = $(this.selectors.burger);
        this.$login = $(this.selectors.login);
        this.$menu = $(this.selectors.menu.root);
        this.$menuItem = this.$menu.find(this.selectors.menu.item);
        this.tl = new TimelineLite({paused: true, reversed: true});
        this.tlMenuList = new TimelineLite({paused: true, reversed: true});
        this.tlMobileMenu = new TimelineLite();
        this.top = $(this.selectors.top);
        this.mid = $(this.selectors.mid);
        this.bot = $(this.selectors.bot);
        this.$pageNavigation = $(this.selectors.navigation.root);
        this.$pageNavItems = this.$pageNavigation.find(this.selectors.navigation.item);
        this.$pageNavLabel = this.$pageNavigation.find(this.selectors.navigation.label);
        this.headerHeight = this.$element.outerHeight(true) * 1.5;
    }

    public init(): any {
        this.hamburgerAnimation();
        this.headerListAnimation();
    }

    public bindEvents() {

        // WAITING FOR CRM
        this.$login.on('click', ()=>{
            this.tl.reversed() ? this.tl.restart() : this.tl.reverse();

            if (g.width < 768) {

                if (this.is.mobileMenuAnimating) {
                    return false;
                }

                if (this.is.mobileOpen) {

                    this.showMobileMenu(false);
                } else {
                    this.showMobileMenu();
                }


            } else {
                this.tlMenuList.reversed() ? this.tlMenuList.restart() : this.tlMenuList.reverse();
            }
        });
        this.$burger.on('click', () => {
            this.tl.reversed() ? this.tl.restart() : this.tl.reverse();

            if (g.width < 768) {

                if (this.is.mobileMenuAnimating) {
                    return false;
                }

                if (this.is.mobileOpen) {

                    this.showMobileMenu(false);
                } else {
                    this.showMobileMenu();
                }


            } else {
                this.tlMenuList.reversed() ? this.tlMenuList.restart() : this.tlMenuList.reverse();
            }
        });
        this.$pageNavItems.on('click', (e) => {
            let $target = $(e.currentTarget);
            this.resetAll();
            $target.addClass('is-active');
        });
        $(window).on('scroll', () => {
            let currY = $(window).scrollTop();

            if (currY > this.headerHeight) {
                this.$element.addClass('header--shadow');
            } else {
                this.$element.removeClass('header--shadow');
            }

        });
    }


    public resize() {
        /*this.initScenes.forEach((scene:any) => {
            g.controller.updateScene(scene, true);
        });*/


        if (this.is.mobileOpen && g.width >= 768) {
            this.tl.reversed() ? this.tl.restart() : this.tl.reverse();
            this.showMobileMenu(false, 0);
            this.resetInlineStyles(this.$menuItem);
        } else if (!this.tlMenuList.reversed() && g.width < 768) {
            this.tlMenuList.reverse();
            this.tl.reverse();
        }

    }

    public showMobileMenu(dir: boolean = true, durr: number = this.mobileMenuAnimDurr) {
        let tl = new TimelineLite();

        this.is.mobileMenuAnimating = true;

        if (!dir) {

            tl.fromTo(this.$menu, durr, {x: '0%'}, {
                x: '100%', ease: Power3.easeInOut, onComplete: () => {
                    TweenMax.set(this.$element, {className: '-=is-open'});
                    this.resetInlineStyles(this.$menuItem);
                    this.resetInlineStyles(this.$menu);
                    this.is.mobileOpen = false;
                    this.is.mobileMenuAnimating = false;
                }
            });
            this.headroom.init();

        } else {
            TweenMax.set(this.$element, {className: '+=is-open'});
            tl
                .fromTo(this.$menu, durr, {x: '100%'}, {
                    x: '0%', ease: Power3.easeInOut, onComplete: () => {
                        this.is.mobileOpen = true;
                        this.is.mobileMenuAnimating = false;
                    }
                })
                .staggerFromTo(this.$menuItem, durr, {x: 10, autoAlpha: 0}, {
                    autoAlpha: 1,
                    x: 0,
                    ease: Power3.easeInOut
                }, 0.03);
            this.headroom.destroy();
        }


    }

    public hamburgerAnimation() {
        this.tl

            .to(this.top, .2, {y: '-2', transformOrigin: '50% 50%'}, 0)
            .to(this.bot, .2, {y: '2', transformOrigin: '50% 50%'}, 0)
            .to(this.mid, .2, {scale: 0, transformOrigin: '50% 50%'}, 0)
            .add('rotate')
            .to(this.top, .2, {y: '1'}, 'rotate')
            .to(this.bot, .2, {y: '-2.2'}, 'rotate')
            .to(this.top, .2, {rotationZ: 45, transformOrigin: '50% 50%'}, 'rotate')
            .to(this.bot, .2, {rotationZ: -45, transformOrigin: '50% 50%'}, 'rotate')
    }

    public headerListAnimation() {
        this.tlMenuList
            .staggerTo(this.$menuItem, 0.3, {autoAlpha: 1, x: 0, ease: Power3.easeInOut}, 0.05);
    }

    public resetInlineStyles(items: JQuery) {
        return items.each((index, item) => {
            item.removeAttribute('style');
        })
    }

    public resetAll() {
        this.$pageNavItems.each((i, item) => {
            $(item).removeClass('is-active');
        })
    }

    /*public pageScrollNavigation() {

        this.initScenes = [];
        /!*this.scenes.forEach((scene, i) => {

            let arr = Array.from(this.$pageNavLabel);

            let x = new ScrollMagic.Scene({
                triggerElement: scene.id,
                duration: this.$pageNavigation.outerHeight(),
                triggerHook: (g.height / 2 + this.$pageNavigation.outerHeight(true) / 2) / (g.height / 100) / 100,
                reverse: true
            })
                .on('leave', (e:any) => {
                    if (e.scrollDirection === 'REVERSE') {
                        this.resetAll();
                        $(this.$pageNavItems[--i]).addClass('is-active');
                    }
                })
                .on('enter', (e:any) => {
                    if (e.scrollDirection === 'FORWARD') {
                        this.resetAll();
                        $(this.$pageNavItems[++i]).addClass('is-active');
                    }
                })
                .setTween(scene.mode === 'dark' ? new TimelineMax()
                    .staggerTo(arr.reverse(), 0.5, {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        ease: Power3.easeInOut
                    }, 0.4) : new TimelineMax()
                    .staggerTo(arr.reverse(), 0.5, {backgroundColor: '#ffffff', ease: Power3.easeInOut}, 0.4))
                .addTo(g.controller);

            this.initScenes.push(x);
        });*!/
    }*/
}