/// <reference path='./patterns/gsap-interface.ts' />
/// <reference path='./patterns/gsap-element.ts' />
/// <reference path='./elements/footer.ts' />
/// <reference path='./elements/header.ts' />
/// <reference path='./elements/carousel.ts' />
/// <reference path='./elements/tabs.ts' />
/// <reference path='./elements/contact.ts' />
/// <reference path='./elements/trailer.ts' />
/// <reference path='./elements/intro.ts' />
/// <reference path='./elements/navigation-control.ts' />
/// <reference path='./elements/picture-control.ts' />
/// <reference path='./elements/map.ts' />
/// <reference path='./elements/slider.ts' />
/// <reference path='./elements/loader.ts' />
/// <reference path='./elements/cookie.ts' />
/// <reference path='./elements/vimeo.ts' />

class gsapObjects {
    public elements: { [id: string]: Igsap; }
    constructor() {
        let that = this;
        this.elements = {};

        //this.newObject(new Footer())

        //this.newObject(new NavigationControl());
        //this.newObject(new Carousel($('.js-carousel-holder')));
        this.newObject(new Header($('.header')));
        this.newObject(new Trailer($('.trailer')));
        this.newObject(new Intro($('.intro')));
        this.newObject(new Contact($('.contact')));
        g.GoogleMap = this.newObject(new GoogleMap());

        if (g.isGlobal && Global.cookie.bar && document.cookie.indexOf(Global.cookie.name) < 0) {
            g.cookieBar = this.newObject(new Cookie(Global.cookie.text, Global.cookie.yes, Global.cookie.no)) as Cookie;
        }

        Array.prototype.forEach.call(
            document.querySelectorAll("picture"),
            function (el: any, id: any) {
                that.newObject(new PictureControl(el, id));
            }
        );

        $(".tab-slider").each(function(){
            that.newObject(new Carousel($(this)));
        });

        $(".carousel__item").each(function(i,item){
            that.newObject(new VimeoContainer($(this)));
        })

        new ModalVideo('.carousel__item.js-slide', {channel: 'vimeo'});

        /*Array.prototype.forEach.call(
            document.querySelectorAll(".slider-wrapper"),
            function (el: any, id: any) {
                let slider = new RootSlider(el, id)
                that.newObject(slider);

                //g.gui.add(slider, 'actualSlide');
                //g.gui.add(slider, 'animation.progress');
            }
        );*/
        // TODO: add more objects inicialization
        // and add it in to g (global class)

    }

    newObject(obj: Igsap): Igsap {
        this.elements[obj.name] = obj;
        return obj;
    }

    getElementByName(name: string) {
        return this.elements[name];
    }

    resize() {
        for (var key in this.elements) {
            this.elements[key].resize();
        }
    }

    afterLoadInitialization() {
        for (var key in this.elements) {
            if (this.elements[key].init != null)
                this.elements[key].init();
        }
    }
}



